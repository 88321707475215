// src/components/Dashboard/HotelRatesSearch/HotelSearchContext.js

import React, { createContext, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHotelSelection } from 'hooks/useHotelSelection';
import { useDateSelection } from 'hooks/useDateSelection';
import { useOccupancyDetails } from 'hooks/useOccupancyDetails';
import { useHotelDataFetcher } from 'hooks/useHotelDataFetcher';
import { useRatesProcessing } from 'hooks/useRatesProcessing';
import { AuthContext } from 'components/Common/AuthContext';

const HotelSearchContext = createContext();

const HotelSearchProvider = ({ children }) => {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const { fetchHotelData, fetchRates } = useHotelDataFetcher();
  const { processRates } = useRatesProcessing();

  const {
    selectedCountry,
    setSelectedCountry,
    selectedRegion,
    setSelectedRegion,
    selectedHotel,
    setSelectedHotel,
    selectedRoomType,
    setSelectedRoomType,
    resetState: resetHotelSelection,
  } = useHotelSelection();

  const { selectedDates, setSelectedDates, onDateSelect, resetState: resetDateSelection } = useDateSelection();

  const {
    additionalAdults,
    setAdditionalAdults,
    childrenCount,
    setChildrenCount,
    occupancyDetails,
    setOccupancyDetails,
    resetState: resetOccupancyDetails,
  } = useOccupancyDetails();

  const [priceDetails, setPriceDetails] = useState({
    dailyPrices: [],
    rackRateTotal: 0,
    netRateTotal: 0,
    grandTotal: 0,
    noRates: false,
  });

  const resetState = useCallback(() => {
    resetHotelSelection();
    resetDateSelection();
    resetOccupancyDetails();
    setSelectedHotel(null); // Clear the selected hotel
    setPriceDetails({
      dailyPrices: [],
      rackRateTotal: 0,
      netRateTotal: 0,
      grandTotal: 0,
      noRates: false,
    });
    console.log('State reset'); // Debug log
  }, [resetHotelSelection, resetDateSelection, resetOccupancyDetails, setSelectedHotel]);

  const resetDependentStates = useCallback((changedState) => {
    switch (changedState) {
      case 'country':
        setSelectedRegion(null);
        // fall through to reset further dependencies
      case 'region':
        setSelectedHotel(null);
        // fall through to reset further dependencies
      case 'hotel':
        setSelectedRoomType(null);
        setSelectedDates({ start: null, end: null });
        setAdditionalAdults(0);
        setChildrenCount(0);
        setOccupancyDetails(null);
        setPriceDetails({
          dailyPrices: [],
          rackRateTotal: 0,
          netRateTotal: 0,
          grandTotal: 0,
          noRates: false,
        });
        break;
      case 'roomType':
      case 'dates':
        setPriceDetails({
          dailyPrices: [],
          rackRateTotal: 0,
          netRateTotal: 0,
          grandTotal: 0,
          noRates: false,
        });
        break;
      default:
        break;
    }
    console.log(`Dependent states reset due to ${changedState}`); // Debug log
  }, [
    setSelectedRegion, 
    setSelectedHotel, 
    setSelectedRoomType, 
    setSelectedDates, 
    setAdditionalAdults, 
    setChildrenCount, 
    setOccupancyDetails
  ]);

  const fetchAndProcessRates = useCallback(async () => {
    if (!authToken) {
      navigate('/login');
      return;
    }
    if (!selectedRoomType || !selectedDates.start || !selectedDates.end) return;

    try {
      const ratesData = await fetchRates(selectedRoomType, {
        start: selectedDates.start.toISOString().split('T')[0],
        end: selectedDates.end.toISOString().split('T')[0],
      });
      if (ratesData) {
        const processedRates = processRates(ratesData, additionalAdults, childrenCount);
        setPriceDetails({ ...processedRates, noRates: false });
        console.log('Rates fetched and processed:', processedRates); // Debug log
      }
    } catch (error) {
      console.error('Error fetching and processing rates:', error);
      setPriceDetails({
        dailyPrices: [],
        rackRateTotal: 0,
        netRateTotal: 0,
        grandTotal: 0,
        noRates: true,
      });
    }
  }, [authToken, navigate, selectedRoomType, selectedDates, additionalAdults, childrenCount, fetchRates, processRates]);

  useEffect(() => {
    fetchAndProcessRates();
  }, [fetchAndProcessRates]);

  const value = useMemo(
    () => ({
      selectedCountry,
      setSelectedCountry: (value) => {
        setSelectedCountry(value);
        resetDependentStates('country');
      },
      selectedRegion,
      setSelectedRegion: (value) => {
        setSelectedRegion(value);
        resetDependentStates('region');
      },
      selectedHotel,
      setSelectedHotel: (value) => {
        setSelectedHotel(value);
        resetDependentStates('hotel');
      },
      selectedRoomType,
      setSelectedRoomType: (value) => {
        setSelectedRoomType(value);
        resetDependentStates('roomType');
      },
      selectedDates,
      setSelectedDates: (value) => {
        setSelectedDates(value);
        resetDependentStates('dates');
      },
      onDateSelect,
      additionalAdults,
      setAdditionalAdults,
      childrenCount,
      setChildrenCount,
      occupancyDetails,
      setOccupancyDetails,
      priceDetails,
      resetState,
      resetDependentStates,
    }),
    [
      selectedCountry,
      setSelectedCountry,
      selectedRegion,
      setSelectedRegion,
      selectedHotel,
      setSelectedHotel,
      selectedRoomType,
      setSelectedRoomType,
      selectedDates,
      setSelectedDates,
      onDateSelect,
      additionalAdults,
      setAdditionalAdults,
      childrenCount,
      setChildrenCount,
      occupancyDetails,
      setOccupancyDetails,
      priceDetails,
      resetState,
      resetDependentStates,
    ]
  );

  console.log('HotelSearchContext value:', value); // Debug log

  return <HotelSearchContext.Provider value={value}>{children}</HotelSearchContext.Provider>;
};

export { HotelSearchContext, HotelSearchProvider };