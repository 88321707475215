// src/components/Dashboard/HotelRatesSearch/PriceDisplay.js

import React, { useContext, useEffect, useState } from 'react';
import { HotelSearchContext } from './HotelSearchContext';
import { ShoppingCartContext } from '../ShoppingCart/ShoppingCartContext';
import { fetchHotelDetails } from 'services/hotels_api';
import styles from 'styles/HotelSearchApp.module.css';

const PriceDisplay = React.memo(() => {
  const { priceDetails, selectedHotel, selectedDates, resetState } = useContext(HotelSearchContext);
  const { addItemToCart } = useContext(ShoppingCartContext);
  const [hotelName, setHotelName] = useState('Unknown Hotel');
  const [buttonText, setButtonText] = useState('Add to Itinerary');
  const [buttonClass, setButtonClass] = useState(styles.addToItineraryButton);

  const {
    dailyPrices,
    rackRateTotal,
    netRateTotal,
    grandTotal,
    noRates
  } = priceDetails;

  useEffect(() => {
    const getHotelDetails = async () => {
      if (!selectedHotel) return;

      try {
        const response = await fetchHotelDetails(selectedHotel);
        const details = response.data;
        if (details && details.name) {
          setHotelName(details.name);
        }
      } catch (error) {
        console.error('Failed to fetch hotel details:', error);
      }
    };

    getHotelDetails();
  }, [selectedHotel]);

  const formatPrice = (price) => {
    return `US$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(price)}`;
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).format(new Date(date));
  };

  const handleAddToCart = () => {
    const item = {
      id: new Date().getTime(),
      details: {
        hotelName: hotelName,
        dailyPrices,
        rackRateTotal: parseFloat(rackRateTotal),
        netRateTotal: parseFloat(netRateTotal),
        price: parseFloat(grandTotal),
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
      },
      quantity: 1,
    };
    addItemToCart(item);
    setButtonText('Added!');
    setButtonClass(styles.addedToItineraryButton);
    setTimeout(() => {
      setButtonText('Add to Itinerary');
      setButtonClass(styles.addToItineraryButton);
    }, 2000);
  };

  const handleResetSearch = () => {
    resetState();
  };

  if (!dailyPrices.length) {
    return null; // Do not render if there are no daily prices to display
  }

  return (
    <div className={styles.priceDisplayContainer}>
      <div className={styles.priceDisplay}>
        <h3>Price Breakdown</h3>
        {noRates ? (
          <div>No rates available for the selected dates.</div>
        ) : (
          <>
            <table className={styles.priceTable}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {dailyPrices.map((price, index) => (
                  <tr key={index}>
                    <td>{formatDate(price.date)}</td>
                    <td>{formatPrice(price.netRate)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className={styles.deemphasized}>
                  <td>Net Rate total with taxes:</td>
                  <td>{formatPrice(netRateTotal)}</td>
                </tr>
                <tr className={styles.emphasized}>
                  <td>Rack Rate total with taxes:</td>
                  <td>{formatPrice(rackRateTotal)}</td>
                </tr>
              </tfoot>
            </table>
            <button onClick={handleAddToCart} className={buttonClass}>
              {buttonText}
            </button>
            <button onClick={handleResetSearch} className={styles.clearSearchButton}>
              Clear Search
            </button>
          </>
        )}
      </div>
    </div>
  );
});

export default PriceDisplay;