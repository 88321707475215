// File: src/components/Dashboard/Tours/ToursRateDisplay.js

import React, { useEffect, useState, useContext } from 'react';
import { fetchTourRatesByTourNameId } from 'services/tours_api';
import { useTours } from 'components/Dashboard/Tours/ToursContext';
import { ShoppingCartContext } from 'components/Dashboard/ShoppingCart/ShoppingCartContext';
import styles from 'styles/Tours.module.css';

const ToursRateDisplay = () => {
    const { tourNameId, setRate, activityName, setProviderId, setProviderName } = useTours();  // Set provider name in context
    const { addItemToCart } = useContext(ShoppingCartContext);
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getRates = async () => {
            if (tourNameId) {
                setLoading(true);
                setError(null);
                try {
                    // Fetch tour rates by tour name ID
                    const ratesResponse = await fetchTourRatesByTourNameId(tourNameId);
                    const fetchedRates = ratesResponse.data.results || [];

                    if (fetchedRates.length === 0) {
                        throw new Error("No rates found for the selected tour");
                    }

                    setRates(fetchedRates);

                    const firstRate = fetchedRates[0] || {};
                    setRate(firstRate);
                    setProviderId(firstRate.provider_id || null);
                    setProviderName(firstRate.provider_name || null);  // Set the provider name
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                    setError('Failed to fetch data.');
                    setRates([]);
                    setRate(null);
                    setProviderId(null);
                    setProviderName(null);
                } finally {
                    setLoading(false);
                }
            } else {
                // Clear rates if tourNameId is null
                setRates([]);
                setRate(null);
                setProviderId(null);
                setProviderName(null);
            }
        };

        getRates();
    }, [tourNameId, setRate, setProviderId, setProviderName]);

    if (loading) {
        return <div className={styles.rateDisplay}>Loading rates...</div>;
    }

    if (error) {
        return <div className={`${styles.rateDisplay} ${styles.error}`}>{error}</div>;
    }

    if (!rates.length) {
        return <div className={styles.rateDisplay}>Select a tour for rates</div>;
    }

    return (
        <div className={styles.rateDisplay}>
            <div>
                <h3 className={styles.centerTitle}>Available Rates</h3>
                <table className={styles.rateTable}>
                    <thead>
                        <tr>
                            <th>Activity</th>
                            <th colSpan="2">Regular&nbsp;Adults</th>
                            <th colSpan="2">Regular&nbsp;Children</th>
                            <th colSpan="2">Private&nbsp;Adults</th>
                            <th colSpan="2">Private&nbsp;Children</th>
                            <th>Min&nbsp;Pax</th>
                            <th>Min&nbsp;Age</th>
                            <th>Provider</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>Rack</th>
                            <th>Net</th>
                            <th>Rack</th>
                            <th>Net</th>
                            <th>Rack</th>
                            <th>Net</th>
                            <th>Rack</th>
                            <th>Net</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rates.map(rate => (
                            <tr key={rate.id}>
                                <td>{activityName}</td>
                                <td>{rate.reg_adults_rack}</td>
                                <td>{rate.reg_adults_net}</td>
                                <td>{rate.reg_children_rack}</td>
                                <td>{rate.reg_children_net}</td>
                                <td>{rate.priv_adults_rack}</td>
                                <td>{rate.priv_adults_net}</td>
                                <td>{rate.priv_children_rack}</td>
                                <td>{rate.priv_children_net}</td>
                                <td>{rate.min_pax}</td>
                                <td>{rate.min_age}</td>
                                <td>{rate.provider_name}</td> 
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ToursRateDisplay;