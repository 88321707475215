// src/components/Dashboard/Transfers/RateDisplay.js

import React, { useEffect, useState, useContext } from 'react';
import { fetchRates } from 'services/transfers_api';
import { useTransfers } from 'components/Dashboard/Transfers/TransfersContext';
import { ShoppingCartContext } from 'components/Dashboard/ShoppingCart/ShoppingCartContext';
import styles from 'styles/Transfers.module.css';

const RateDisplay = () => {
    const { yearId, pickupId, dropOffId, pickupName, dropOffName } = useTransfers();
    const { addItemToCart } = useContext(ShoppingCartContext);
    const [rates, setRates] = useState([]);
    const [buttonStates, setButtonStates] = useState({});

    useEffect(() => {
        const getRates = async () => {
            if (yearId && pickupId && dropOffId) {
                try {
                    const response = await fetchRates(yearId, pickupId, dropOffId);
                    if (response && response.data && Array.isArray(response.data)) {
                        setRates(response.data);
                        // Initialize button states
                        const initialButtonStates = response.data.reduce((acc, rate) => {
                            acc[rate.id] = {
                                text: 'Add to Itinerary',
                                class: styles.addToItineraryButton
                            };
                            return acc;
                        }, {});
                        setButtonStates(initialButtonStates);
                    } else {
                        setRates([]);
                    }
                } catch (error) {
                    setRates([]);
                }
            } else {
                setRates([]);
            }
        };

        getRates();
    }, [yearId, pickupId, dropOffId]);

    const handleAddToCart = (rate) => {
        const item = {
            id: rate.id,
            name: 'Transfer Rate',
            details: {
                groupSize: rate.group_size,
                netRate: parseFloat(rate.net_rate_with_taxes),
                rackRate: parseFloat(rate.rack_rate_with_taxes),
                price: parseFloat(rate.net_rate_with_taxes),
                pickupLocation: pickupName,
                dropOffLocation: dropOffName,
            },
            quantity: 1,
        };
        addItemToCart(item);
        setButtonStates(prevStates => ({
            ...prevStates,
            [rate.id]: {
                text: 'Added!',
                class: styles.addedToItineraryButton
            }
        }));
        setTimeout(() => {
            setButtonStates(prevStates => ({
                ...prevStates,
                [rate.id]: {
                    text: 'Add to Itinerary',
                    class: styles.addToItineraryButton
                }
            }));
        }, 2000);
    };

    if (!rates.length) {
        return <div className={styles.rateDisplay}>Select a transfer for rates</div>;
    }

    const formatRate = (rate) => {
        const netRate = parseFloat(rate.net_rate_with_taxes).toFixed(2);
        const rackRate = parseFloat(rate.rack_rate_with_taxes).toFixed(2);
        return { netRate, rackRate };
    };

    return (
        <div className={styles.rateDisplay}>
            <div className={styles.rateTableContainer}>
                <table className={styles.rateTable}>
                    <thead>
                        <tr>
                            <th>Group Size</th>
                            <th>Net Rate with Taxes</th>
                            <th>Rack Rate with Taxes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rates.map(rate => {
                            const { netRate, rackRate } = formatRate(rate);
                            const buttonState = buttonStates[rate.id] || {
                                text: 'Add to Itinerary',
                                class: styles.addToItineraryButton
                            };
                            return (
                                <tr key={rate.id}>
                                    <td>{rate.group_size}</td>
                                    <td>${netRate}</td>
                                    <td>${rackRate}</td>
                                    <td>
                                        <button 
                                            onClick={() => handleAddToCart(rate)} 
                                            className={buttonState.class}>
                                            {buttonState.text}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RateDisplay;