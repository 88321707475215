// src/components/Dashboard/HotelRatesSearch/Dropdowns/RoomTypeDropdown.js

import React, { useState, useEffect, useContext } from 'react';
import { fetchRoomTypesByHotel } from 'services/hotels_api';
import { HotelSearchContext } from '../HotelSearchContext';
import { Panel, List, Tooltip, Whisper } from 'rsuite';
import styles from 'styles/HotelSearchApp.module.css';

function RoomTypeDropdown() {
  const { selectedHotel, selectedRoomType, setSelectedRoomType } = useContext(HotelSearchContext);
  const [roomTypes, setRoomTypes] = useState([]);

  useEffect(() => {
    setRoomTypes([]);
  }, [selectedHotel]);

  useEffect(() => {
    const getRoomTypes = async () => {
      if (!selectedHotel) return;
      try {
        const response = await fetchRoomTypesByHotel(selectedHotel);
        setRoomTypes(response.data.results);
      } catch (error) {
        console.error('Error fetching room types:', error);
      }
    };

    getRoomTypes();
  }, [selectedHotel]);

  const handleRoomTypeSelect = (roomTypeId) => {
    setSelectedRoomType(roomTypeId);
  };

  return (
    <Panel header="Select Room Type" shaded className={styles.panel}>
      <List>
        {roomTypes.map((roomType) => (
          <Whisper
            key={roomType.id}
            trigger="hover"
            speaker={<Tooltip>{roomType.occupancy_details.room_description || 'No description available'}</Tooltip>}
            placement="right"
          >
            <List.Item
              onClick={() => handleRoomTypeSelect(roomType.id)}
              className={`${selectedRoomType === roomType.id ? styles.selected : ''} ${styles.rsuiteListItem}`}
            >
              {roomType.type}
            </List.Item>
          </Whisper>
        ))}
      </List>
    </Panel>
  );
}

export default RoomTypeDropdown;