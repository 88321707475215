// File: src/components/Dashboard/Tours/0.Tours_Frontpage.js

import React from 'react';
import SelectYearDropdown from './Dropdowns/SelectYearDropdown';
import RegionDropdown from './Dropdowns/RegionDropdown';
import ActivityDropdown from './Dropdowns/ActivityDropdown';
import ToursRateDisplay from './ToursRateDisplay';
import ToursQuoteGenerator from './ToursQuoteGenerator';
import ShoppingCart from '../ShoppingCart/ShoppingCart';
import TourPolicy from './TourPolicy'; // Import the TourPolicy component
import { useTours, ToursProvider } from './ToursContext';
import styles from 'styles/Tours.module.css';

const ToursFrontpageContent = () => {
  const { providerId } = useTours(); // Assuming providerId is part of the context

  return (
    <div className={styles.toursFrontpage}>
      <div className={styles.searchBar}>
        <SelectYearDropdown />
        <RegionDropdown />
        <ActivityDropdown />
      </div>
      <div className={styles.rateDisplayContainer}>
        <ToursRateDisplay />
      </div>
      {providerId && (
        <div className={styles.policyContainer}>
          <TourPolicy />
        </div>
      )}
      <div className={styles.quoteGeneratorContainer}>
        <ToursQuoteGenerator />
      </div>
      <div className={styles.shoppingCartContainer}>
        <ShoppingCart />
      </div>
    </div>
  );
};

const ToursFrontpage = () => (
  <ToursProvider>
    <ToursFrontpageContent />
  </ToursProvider>
);

export default ToursFrontpage;